import React, { useState, useEffect } from "react";

const ScrollToTopButton: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const mainContent = document.getElementById("main-content");

    if (!mainContent) return;

    const toggleVisibility = () => {
      if (mainContent.scrollTop > 20) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    mainContent.addEventListener("scroll", toggleVisibility);

    return () => mainContent.removeEventListener("scroll", toggleVisibility);
  }, []);

  // Scroll to top function
  const scrollToTop = () => {
    const mainContent = document.getElementById("main-content");
    if (mainContent) {
      mainContent.scrollTo({
        top: 0,
        behavior: "smooth", // Smooth scroll effect
      });
    }
  };

  return (
    <div>
      {isVisible && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-10 right-10 z-50 bg-blue-500 text-primary-foreground py-2 px-4 h-12 w-12 shadow-lg hover:bg-blue-600 transition"
          style={{ borderRadius: "50%" }}
        >
          <img src="/up-arrow.png" alt="scrollToTop" />
        </button>
      )}
    </div>
  );
};

export default ScrollToTopButton;
