import React, { useEffect } from "react";
import RootApp from "./components/Main";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import GalleryPage from "./components/Galley";
import ScrollToTopButton from "./components/ScrollToTopBtn";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const mainContent = document.getElementById("main-content");
    if (mainContent) {
      mainContent.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
};

const App: React.FC = () => {
  return (
    <div className="flex h-screen w-full overflow-hidden">
      <Router>
        <div className="flex grow flex-col content-start overflow-hidden bg-background">
          {/* <Navbar /> */}
          <div
            id="main-content"
            className="scrollbar-thumb-rounded scrollbar-track-rounded grow overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-track-transparent scrollbar-thumb-muted"
          >
            <ScrollToTop />
            <ScrollToTopButton />
            <Routes>
              <Route path="/" element={<RootApp />} />
              <Route path="/gallery" element={<GalleryPage />} />
            </Routes>
          </div>
        </div>
      </Router>
    </div>
  );
};

export default App;
