import React from "react";

const EnglishTribute: React.FC = () => {
  return (
    <div className="container mx-auto p-6">
      <h2 className="text-center text-xxxl font-bold mb-6 text-foreground">
        In Loving Memory of Dr Boubakar Barry (WACREN CEO)
      </h2>
      <p className="text-base text-forebackground mb-4 font-normal max-w-full break-words overflow-hidden">
        With profound sadness, we announce the passing of Dr Boubakar Barry, CEO
        of WACREN, on Wednesday, September 18, 2024, after a short illness.
      </p>
      <p className="text-base text-forebackground mb-4 font-normal max-w-full break-words overflow-hidden">
        Dr. Barry’s legacy extends beyond{" "}
        <a
          href="https://wacren.net/en/team_member/dr-boubakar-barry-2/"
          target="_blank"
          rel="noreferrer"
          className="text-blue-500"
        >
          his role at WACREN
        </a>
        . He was instrumental in establishing and growing National and Regional
        Research and Education Networks (NRENs) across Africa. His pioneering
        efforts significantly strengthened digital connectivity and
        collaboration in research and education across the continent.
      </p>
      <p className="text-base text-forebackground mb-4 font-normal max-w-full break-words overflow-hidden">
        Dr Barry led with distinction for over 13 years at WACREN, transforming
        the organisation into a powerhouse of digital infrastructure, driving
        innovation, and fostering partnerships that strengthened the research
        and education community in West and Central Africa and beyond. His
        visionary leadership and commitment to technological progress were
        instrumental in WACREN’s growth and success.
      </p>
      <p className="text-base text-forebackground mb-4 font-normal max-w-full break-words overflow-hidden">
        Dr. Barry will be remembered as a deeply committed African leader with
        unwavering integrity, a bridge-builder, and a tireless advocate for
        research, education, and innovation. His legacy is a testament to his
        dedication to improving education and research across Africa.
      </p>
      <p className="text-base text-forebackground mb-4 font-normal max-w-full break-words overflow-hidden">
        The WACREN community mourns this great loss, and our thoughts and
        prayers are with his family during this difficult time. Dr. Barry’s
        selfless service to WACREN, Africa, and the global academic community
        will continue to inspire future generations.
      </p>
      <p className="text-base text-forebackground mb-4 font-normal max-w-full break-words overflow-hidden">
        Rest in perfect peace, Dr. Barry!
      </p>

      <div className="mt-8">
        <h3 className="font-bold text-lg mb-2">
          The family of Dr. Barry has announced the following funeral
          arrangements:
        </h3>
        <ul className="list-disc pl-6">
          <li>
            <strong className="text-base text-foreground">
              Friday, 20 September 2024
            </strong>
            <ul className="list-inside list-decimal">
              <li className="text-base text-foreground">
                Gathering at the Funérarium PFG, 65 Rue Henri Barbusse, 95100
                Argenteuil at 11:00 am
              </li>
              <li className="text-base text-foreground">
                Salat Janaza at the Argenteuil mosque at 1:00 pm, 2 Boulevard de
                la Résistance, 95100 Argenteuil
              </li>
            </ul>
          </li>
        </ul>

        <ul className="list-disc pl-6">
          <li>
            <strong className="text-base text-foreground">
              Saturday, 21 September 2024
            </strong>
            <ul className="list-inside list-decimal">
              <li className="text-base text-foreground">
                14h30 - Arrival of the body at AIBD
              </li>
            </ul>
          </li>
        </ul>
        <ul className="list-disc pl-6">
          <li>
            <strong className="text-base text-foreground">
              Sunday, 22 September 2024
            </strong>
            <ul className="list-inside list-decimal">
              <li className="text-base text-foreground">
                9h00 - Preparation of the body at the CHU de FANN, followed by
                the funeral prayer and burial at the Ouakam cemeteries, Dakar -
                Senegal
              </li>
            </ul>
          </li>
        </ul>

        <p className="text-base text-forebackground my-4 font-normal max-w-full break-words overflow-hidden">
          After the funeral, condolences will be received at the family home in
          Ouakam, next to the Pharmacie Cite Avion, on Sunday, 22 September
          2024.
        </p>
      </div>
    </div>
  );
};

export default EnglishTribute;
