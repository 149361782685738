import React, { useState } from "react";
import { useMessageContext } from "../context/MessagesContext";

interface MessageFormProps {
  onSuccess: () => void;
}

const MessageForm: React.FC<MessageFormProps> = ({ onSuccess }) => {
  const [name, setName] = useState("");
  const [organization, setOrganization] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const { addMessage, isEnglish } = useMessageContext();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    const newMessage = { name, organization, message };

    try {
      const response = await fetch(
        // "https://docflow-backend.wacren.net/api/tribute/message/",
        "https://pidslinkapi.ren.africa/api/tribute/message/",

        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(newMessage),
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log(result);
        addMessage(newMessage);

        // Reset form fields
        setName("");
        setOrganization("");
        setMessage("");
        setLoading(false);
        onSuccess();
      } else {
        setLoading(false);
        console.error("Failed to submit message.");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error submitting message:", error);
    }
  };

  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-4">
        {isEnglish ? "Share Your Memories" : "Partagez vos souvenirs"}
      </h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-bold mb-1">
            {isEnglish ? "Name" : "Nom"}
          </label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="focus:shadow-outline h-10 w-full appearance-none rounded bg-blue-200 py-2 text-sm leading-tight text-gray-700 shadow-md focus:outline-none"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-bold mb-1">
            {isEnglish ? "Organization" : "L'organisation"}
          </label>
          <input
            type="text"
            value={organization}
            onChange={(e) => setOrganization(e.target.value)}
            className="focus:shadow-outline h-10 w-full appearance-none rounded bg-blue-200 py-2 text-sm leading-tight text-gray-700 shadow-md focus:outline-none"
          />
        </div>
        <div>
          <label className="block text-sm font-bold mb-1">
            {isEnglish ? "Message" : "Message"}
          </label>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="focus:shadow-outline h-full w-full appearance-none rounded bg-blue-200 py-2 text-sm leading-tight text-gray-700 shadow-md focus:outline-none"
            rows={8}
            cols={6}
            required
          />
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white p-2 rounded-lg"
          disabled={loading}
        >
          {isEnglish ? "Submit" : "Soumettre"}
        </button>
      </form>
    </div>
  );
};

export default MessageForm;
