import React, { useState } from "react";
import Carousel from "./Carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faArrowLeft,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { useMessageContext } from "../context/MessagesContext";
import { Link } from "react-router-dom";

const photos = [
  {
    src: "/assets/funeral1.jpg",
    alt: "Memory 1",
  },
  {
    src: "/assets/funeral2.jpg",
    alt: "Memory 2",
  },
  {
    src: "/assets/funeral4.jpeg",
    alt: "Memory 3",
  },
  {
    src: "/assets/funeral5.jpeg",
    alt: "Memory 4",
  },
  {
    src: "/assets/funeral6.jpeg",
    alt: "Memory 5",
  },
  {
    src: "/assets/funeral7.jpg",
    alt: "Memory 6",
  },
  {
    src: "/assets/funeral8.jpeg",
    alt: "Memory 7",
  },
  {
    src: "/assets/funeral9.jpeg",
    alt: "Memory 8",
  },
  {
    src: "/assets/funeral10.jpeg",
    alt: "Memory 9",
  },
  {
    src: "/assets/funeral3.jpg",
    alt: "Memory 10",
  },
];

const GalleryPage: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);
  const { isEnglish } = useMessageContext();

  const openModal = (index: number) => {
    setCurrentIndex(index);
  };

  const closeModal = () => {
    setCurrentIndex(null);
  };

  const nextImage = () => {
    if (currentIndex !== null) {
      setCurrentIndex((prevIndex) => (prevIndex! + 1) % photos.length);
    }
  };

  const prevImage = () => {
    if (currentIndex !== null) {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? photos.length - 1 : prevIndex! - 1
      );
    }
  };

  return (
    <div className="min-h-screen w-full overflow-hidden bg-background">
      {/* Carousel Section */}
      <section className="mb-8">
        <Carousel />
      </section>
      <h2 className="text-xxxl font-bold text-center mb-6">
        {isEnglish ? "Funeral Photos" : "Photo d'enterrement"}
      </h2>
      <div className="container mx-auto flex items-center justify-end gap-6 mb-8">
        <Link to="/" className="mb-4 p-2 bg-blue-500 text-white rounded">
          {isEnglish ? "Go Back" : "Retour"}
        </Link>
      </div>
      <div className="container mx-auto grid grid-cols-2 md:grid-cols-3 gap-4">
        {photos.map((photo, index) => (
          <div
            key={index}
            className="cursor-pointer hover:opacity-75"
            onClick={() => openModal(index)}
          >
            <img
              src={photo.src}
              alt={photo.alt}
              loading="lazy"
              className="w-full h-52 object-cover rounded-lg"
            />
          </div>
        ))}
      </div>

      {/* Modal for viewing larger images with controls */}
      {currentIndex !== null && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
          <div className="relative">
            {/* Close Button */}
            <button
              onClick={closeModal}
              className="absolute top-2 right-2 text-white text-3xl"
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>

            {/* Current Image */}
            <img
              src={photos[currentIndex].src}
              alt={photos[currentIndex].alt}
              className="w-auto h-auto max-h-screen max-w-screen-lg"
            />

            {/* Display Current Index and Total */}
            <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 text-white text-lg font-bold">
              {currentIndex + 1} / {photos.length}
            </div>

            {/* Previous Button */}
            <button
              onClick={prevImage}
              className="absolute top-1/2 left-2 text-white text-4xl transform -translate-y-1/2"
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>

            {/* Next Button */}
            <button
              onClick={nextImage}
              className="absolute top-1/2 right-2 text-white text-4xl transform -translate-y-1/2"
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default GalleryPage;
