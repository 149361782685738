import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from "react";

// Define the message type
interface Message {
  name: string;
  organization: string;
  message: string;
}

// Define the context interface
interface MessageContextType {
  messages: Message[];
  addMessage: (newMessage: Message) => void;
  isEnglish: boolean; // Add this line
  setIsEnglish: React.Dispatch<React.SetStateAction<boolean>>; // Add this line
}

// Create the Message Context
const MessageContext = createContext<MessageContextType | undefined>(undefined);

// Custom hook to use the MessageContext
export const useMessageContext = () => {
  const context = useContext(MessageContext);
  if (!context) {
    throw new Error("useMessageContext must be used within a MessageProvider");
  }
  return context;
};

// Provider component
export const MessageProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [isEnglish, setIsEnglish] = useState(true);

  // Function to add a message
  const addMessage = (newMessage: Message) => {
    setMessages((prevMessages) => [...prevMessages, newMessage]);
  };

  const fetchMessages = async () => {
    try {
      const response = await fetch(
        // "https://docflow-backend.wacren.net/api/tribute/message/all"
        "https://pidslinkapi.ren.africa/api/tribute/message/all"


      );
      if (response.ok) {
        const data = await response.json();
        const message = data && data.messages;

        setMessages(message);
      } else {
        console.error("Failed to fetch messages");
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  useEffect(() => {
    fetchMessages();
  }, []);

  return (
    <MessageContext.Provider
      value={{ messages, addMessage, isEnglish, setIsEnglish }}
    >
      {children}
    </MessageContext.Provider>
  );
};
