import React from "react";

const FrenchTribute: React.FC = () => {
  return (
    <div className="container mx-auto p-6">
      <h2 className="text-center text-xxxl font-bold mb-6 text-foreground">
        A la mémoire du Dr Boubakar Barry (Directeur Général de WACREN)
      </h2>
      <p className="text-base text-forebackground mb-4 font-normal max-w-full break-words overflow-hidden">
        C'est avec une profonde tristesse que nous annonçons le décès du Dr
        Boubakar Barry, Directeur Général de WACREN, le mercredi 18 septembre
        2024, après une courte maladie.
      </p>
      <p className="text-base text-forebackground mb-4 font-normal max-w-full break-words overflow-hidden">
        L'héritage du Dr Barry va au-delà de son{" "}
        <a
          href="https://wacren.net/en/team_member/dr-boubakar-barry-2/"
          target="_blank"
          rel="noreferrer"
          className="text-blue-500"
        >
          rôle au WACREN
        </a>
        . Il a joué un rôle déterminant dans l'établissement et la croissance
        des réseau nationale et régionale d'éducation et de recherche (NREN) à
        travers l'Afrique. Ses efforts pionniers ont considérablement renforcé
        la connectivité numérique et la collaboration dans le domaine de la
        recherche et de l'éducation sur l'ensemble du continent.
      </p>
      <p className="text-base text-forebackground mb-4 font-normal max-w-full break-words overflow-hidden">
        Le Dr Barry a dirigé avec distinction le WACREN pendant plus de 13 ans,
        transformant l'organisation en une centrale d'infrastructure numérique,
        stimulant l'innovation et encourageant les partenariats qui ont renforcé
        la communauté de la recherche et de l'éducation en Afrique de l'Ouest et
        du Centre et au-delà. Son leadership visionnaire et son engagement en
        faveur du progrès technologique ont joué un rôle déterminant dans la
        croissance et le succès du WACREN.
      </p>
      <p className="text-base text-forebackground mb-4 font-normal max-w-full break-words overflow-hidden">
        Le Dr Barry restera dans les mémoires comme un dirigeant africain
        profondément engagé, à l'intégrité inébranlable, un bâtisseur de ponts
        et un défenseur infatigable de la recherche, de l'éducation et de
        l'innovation. Son héritage témoigne de son dévouement à l'amélioration
        de l'éducation et de la recherche dans toute l'Afrique.
      </p>
      <p className="text-base text-forebackground mb-4 font-normal max-w-full break-words overflow-hidden">
        La communauté WACREN pleure cette grande perte, et nos pensées et nos
        prières accompagnent sa famille dans cette période difficile. Les
        services désintéressés rendus par le Dr Barry au WACREN, à l'Afrique et
        à la communauté universitaire mondiale continueront d'inspirer les
        générations futures.
      </p>
      <p className="text-base text-forebackground mb-4 font-normal max-w-full break-words overflow-hidden">
        Repose en paix, Dr. Barry !
      </p>

      <div className="mt-8">
        <h3 className="font-bold text-lg mb-2">
          La famille du Dr Barry a annoncé l'organisation des funérailles:
        </h3>
        <ul className="list-disc pl-6">
          <li>
            <strong className="text-base text-foreground">
              Vendredi 20 Septembre
            </strong>
            <ul className="list-inside list-decimal">
              <li className="text-base text-foreground">
                Recueillement au Funérarium PFG 65 Rue Henri Barbusse, 95100
                Argenteuil a 11H00
              </li>
              <li className="text-base text-foreground">
                Salat Janaza à la mosquée d'Argenteuil à 13H00 2 Boulevard de la
                Résistance, 95100 Argenteuil
              </li>
            </ul>
          </li>
        </ul>

        <ul className="list-disc pl-6">
          <li>
            <strong className="text-base text-foreground">
              Samedi, 21 Septembre
            </strong>
            <ul className="list-inside list-decimal">
              <li className="text-base text-foreground">
                14h30 - Arrivee du corps a AIBD
              </li>
            </ul>
          </li>
        </ul>
        <ul className="list-disc pl-6">
          <li>
            <strong className="text-base text-foreground">
              Dimanche, 22 Septembre
            </strong>
            <ul className="list-inside list-decimal">
              <li className="text-base text-foreground">
                9h00 - Levee du corps au CHU de FANN, suivie de la priere
                mortuaire et de l'enterrement aux cimetieres de Ouakam,
                Dakar-Senegal
              </li>
            </ul>
          </li>
        </ul>

        <p className="text-base text-forebackground my-4 font-normal max-w-full break-words overflow-hidden">
          Les condoleances seront recues a la maison familiale a Ouakam a cote
          de la Pharmacie Cite Avion, le Dimanche 22 Septembre apres
          l'enterrement.
        </p>
      </div>
    </div>
  );
};

export default FrenchTribute;
