import React from "react";
import EnglishTribute from "./Tribute/EnglishTribute";
import FrenchTribute from "./Tribute/FrenchTribute";
import { useMessageContext } from "../context/MessagesContext";
// import { Link } from "react-router-dom";

const Tribute: React.FC = () => {
  const { isEnglish, setIsEnglish } = useMessageContext();

  return (
    <div>
      <div className="container mx-auto flex items-center justify-end gap-6">
        {/* <Link to="/gallery" className="mb-4 p-2 bg-blue-500 text-white rounded">
          {isEnglish ? "Funeral Photos" : "Photo d'enterrement"}
        </Link> */}
        <button
          onClick={() => setIsEnglish(!isEnglish)}
          className="mb-4 p-2 bg-blue-500 text-white rounded"
        >
          {isEnglish ? "En français" : " Switch to English"}
        </button>
      </div>
      {isEnglish ? <EnglishTribute /> : <FrenchTribute />}
    </div>
  );
};

export default Tribute;
