import React, { useState } from "react";
import { useMessageContext } from "../context/MessagesContext";

const MessageDisplay: React.FC = () => {
  const { messages, isEnglish } = useMessageContext();

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const messagesPerPage = 3;

  // Calculate total pages
  const totalPages = Math.ceil(messages.length / messagesPerPage);

  // Get current messages for the page
  const indexOfLastMessage = currentPage * messagesPerPage;
  const indexOfFirstMessage = indexOfLastMessage - messagesPerPage;
  const currentMessages = messages.slice(
    indexOfFirstMessage,
    indexOfLastMessage
  );

  // Handle page change
  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <div className="p-20">
      <h2 className="text-xxl font-bold mb-4">
        {isEnglish ? "Messages" : "Les messages"}
      </h2>
      {messages.length > 0 ? (
        <>
          {/* Grid display of messages */}
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
            {currentMessages.map((msg, index) => (
              <div key={index} className="border p-4 rounded-md shadow-md">
                <p className="text-base text-forebackground font-normal max-w-full break-words overflow-hidden">
                  <strong>{msg.name}</strong>
                </p>
                <p className="text-xs text-red-500 mb-4 font-normal max-w-full break-words overflow-hidden">
                  {msg.organization}
                </p>
                <p className="text-base text-forebackground my-4 font-normal max-w-full break-words overflow-hidden">
                  {msg.message}
                </p>
              </div>
            ))}
          </div>

          {/* Pagination controls */}
          <div className="flex justify-center mt-6">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              className="px-3 py-1 mr-2 bg-gray-200 rounded-lg hover:bg-gray-300"
              disabled={currentPage === 1}
            >
              {isEnglish ? "Previous" : "Précédent"}
            </button>
            <span className="mx-2">
              Page {currentPage} {isEnglish ? "of" : "du"} {totalPages}
            </span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              className="px-3 py-1 ml-2 bg-gray-200 rounded-lg hover:bg-gray-300"
              disabled={currentPage === totalPages}
            >
              {isEnglish ? "Next" : "Suivant"}
            </button>
          </div>
        </>
      ) : (
        <p>
          {isEnglish
            ? "No messages yet. Be the first to share your thoughts!"
            : "Aucun message pour l'instant. Soyez le premier à partager vos idées !"}
        </p>
      )}
    </div>
  );
};

export default MessageDisplay;

// import React, { useState, useEffect } from "react";
// import { useMessageContext } from "../context/MessagesContext";

// const MessageDisplay: React.FC = () => {
//   const { messages, isEnglish } = useMessageContext();

//   // Carousel state
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const messagesPerPage = 6;

//   const totalSlides = Math.ceil(messages.length / messagesPerPage);

//   const indexOfFirstMessage = currentIndex * messagesPerPage;
//   const currentMessages = messages.slice(
//     indexOfFirstMessage,
//     indexOfFirstMessage + messagesPerPage
//   );

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentIndex((prevIndex) =>
//         prevIndex < totalSlides - 1 ? prevIndex + 1 : 0
//       );
//     }, 60000);

//     return () => clearInterval(interval);
//   }, [totalSlides]);

//   const handlePageChange = (index: number) => {
//     if (index >= 0 && index < totalSlides) {
//       setCurrentIndex(index);
//     }
//   };

//   return (
//     <div className="p-20">
//       <h2 className="text-xxl font-bold mb-4">
//         {isEnglish ? "Messages" : "Les messages"}
//       </h2>

//       {messages.length > 0 ? (
//         <>
//           <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
//             {currentMessages.length > 0 ? (
//               currentMessages.map((msg, index) => (
//                 <div
//                   key={index}
//                   className={`border p-4 rounded-md shadow-md transition-opacity duration-1000 ${
//                     currentIndex ===
//                     Math.floor(indexOfFirstMessage / messagesPerPage)
//                       ? "opacity-100"
//                       : "opacity-0"
//                   }`}
//                 >
//                   <p className="text-base text-forebackground font-normal">
//                     <strong>{msg.name}</strong>
//                   </p>
//                   <p className="text-xs text-red-500 mb-4 font-normal">
//                     {msg.organization}
//                   </p>
//                   <p className="text-base text-forebackground my-4 font-normal max-w-full break-words overflow-hidden">
//                     {msg.message}
//                   </p>
//                 </div>
//               ))
//             ) : (
//               <p>
//                 {isEnglish
//                   ? "No messages to display on this slide."
//                   : "Aucun message à afficher pour cette diapositive."}
//               </p>
//             )}
//           </div>

//           {/* Carousel controls with page numbers */}
//           <div className="flex justify-center items-center mt-6">
//             <button
//               onClick={() => handlePageChange(currentIndex - 1)}
//               className="px-3 py-1 mr-2 bg-gray-200 rounded-lg hover:bg-gray-300"
//               disabled={currentIndex === 0}
//             >
//               {isEnglish ? "Previous" : "Précédent"}
//             </button>
//             <span className="mx-2">
//               {isEnglish ? "Page" : "Page"} {currentIndex + 1}{" "}
//               {isEnglish ? "of" : "du"} {totalSlides}
//             </span>
//             <button
//               onClick={() => handlePageChange(currentIndex + 1)}
//               className="px-3 py-1 ml-2 bg-gray-200 rounded-lg hover:bg-gray-300"
//               disabled={currentIndex === totalSlides - 1}
//             >
//               {isEnglish ? "Next" : "Suivant"}
//             </button>
//           </div>
//         </>
//       ) : (
//         <p>
//           {isEnglish
//             ? "No messages yet. Be the first to share your thoughts!"
//             : "Aucun message pour l'instant. Soyez le premier à partager vos idées !"}
//         </p>
//       )}
//     </div>
//   );
// };

// export default MessageDisplay;
