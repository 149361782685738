import React, { useState, useEffect } from "react";

const carouselItems = [
  {
    id: 1,
    title: "A true patriot of research and education network in Africa",
    image:
      "https://res.cloudinary.com/dtmxtpuar/image/upload/v1726751413/1_iptcm6.jpg",
  },
  {
    id: 2,
    title: "A true leader by example",
    image:
      "https://res.cloudinary.com/dtmxtpuar/image/upload/v1726751414/4_iccr94.jpg",
  },
  {
    id: 3,
    title: "A man with a true spirit of collaboration",
    image:
      "https://res.cloudinary.com/dtmxtpuar/image/upload/v1726764163/3_ysa3cq.jpg",
  },
  {
    id: 4,
    title: "A wise, experienced leader",
    image:
      "https://res.cloudinary.com/dtmxtpuar/image/upload/v1726764247/2_omjuzo.jpg",
  },
  {
    id: 5,
    title: "A wise, experienced leader",
    image:
      "https://res.cloudinary.com/dtmxtpuar/image/upload/v1726781073/Screenshot_2024-09-19_at_21.20.51_e8craj.png",
  },
  {
    id: 6,
    title: "A wise, experienced leader",
    image:
      "https://res.cloudinary.com/dtmxtpuar/image/upload/v1726780993/20230320_152950_hy5vhs.jpg",
  },
  {
    id: 7,
    title: "A wise, experienced leader",
    image:
      "https://res.cloudinary.com/dtmxtpuar/image/upload/v1726780942/IMG_2093_nvokq0.jpg",
  },
  {
    id: 8,
    title: "A wise, experienced leader",
    image:
      "https://res.cloudinary.com/dtmxtpuar/image/upload/v1726781533/0U0A0310_1_akcxlz.jpg",
  },
];

const Carousel: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselItems.length);
    }, 6000); // 3 seconds

    return () => clearInterval(interval); // Clear interval on component unmount
  }, []);

  return (
    <div
      className="relative h-96 w-full flex items-center justify-end bg-cover bg-center"
      style={{
        backgroundImage:
          "url(https://res.cloudinary.com/dtmxtpuar/image/upload/v1726761172/slider_qaruvj.jpg)",
      }}
    >
      <div className="container mx-auto flex items-center justify-end h-full">
        {/* Image (Right side) */}
        {carouselItems.map((item, index) => (
          <div
            key={item.id}
            className={`absolute transition-opacity duration-1000 ${
              index === currentIndex ? "opacity-100" : "opacity-0"
            }`}
          >
            <img
              src={item.image}
              alt={item.title}
              loading="lazy"
              className="w-full h-80 object-cover rounded-lg"
            />
          </div>
        ))}
      </div>

      {/* Dot navigation */}
      <div className="absolute bottom-5 w-full flex justify-center space-x-2">
        {carouselItems.map((_, index) => (
          <div
            key={index}
            onClick={() => setCurrentIndex(index)}
            className={`cursor-pointer h-3 w-3 rounded-full ${
              currentIndex === index ? "bg-white" : "bg-gray-400"
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
