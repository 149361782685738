import React, { useState } from "react";
import { useMessageContext } from "../context/MessagesContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Carousel from "./Carousel";
import MessageDisplay from "./DisplayMessage";
import MessageForm from "./Message";
import Tribute from "./Tribute";

const RootApp: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isEnglish } = useMessageContext();

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  return (
    <div className="min-h-screen w-full overflow-hidden bg-background">
      {/* Carousel Section */}
      <section className="mb-8">
        <Carousel />
      </section>

      {/* Tribute Section */}
      <section className="mb-8">
        <Tribute />
      </section>

      {/* Button to trigger modal */}
      <section className="mb-8 text-center">
        <button
          onClick={toggleModal}
          className="bg-blue-500 text-white px-4 py-2 rounded-lg"
        >
          {isEnglish ? "Share Your Memories" : "Partagez vos souvenirs"}
        </button>
      </section>

      {/* Message Form Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full relative">
            {/* Close button */}
            <button
              onClick={toggleModal}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            >
              <FontAwesomeIcon icon={faTimes} className="w-6 h-6" />
            </button>
            {/* Message Form */}
            <MessageForm onSuccess={toggleModal} />
          </div>
        </div>
      )}

      {/* Display Messages */}
      <div className="mb-8">
        <MessageDisplay />
      </div>
    </div>
  );
};

export default RootApp;
